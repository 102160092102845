import { OfflineMap, OfflineMapStatus } from '../../types/offlineMaps.types';
import { TypeKeys } from '../actions';
import { OfflineMapsActions } from './offlineMaps.actions';

export interface OfflineMapsState {
  maps: OfflineMap[];
  ambientCacheEnabled: boolean;
}

const initialState: OfflineMapsState = {
  maps: [],
  ambientCacheEnabled: true
};

const offlineMapsReducer = (state = initialState, action: OfflineMapsActions): OfflineMapsState => {

  switch (action.type) {

    case TypeKeys.OFFLINE_MAPS_CREATE: {
      return {
        ...state,
        maps: [
          ...state.maps,
          action.map
        ]
      };
    }

    case TypeKeys.OFFLINE_MAPS_DELETE: {
      const uuidsToRemove = action.uuids;

      return {
        ...state,
        maps: state.maps.filter(map => !uuidsToRemove.includes(map.uuid))
      };
    }

    case TypeKeys.OFFLINE_MAPS_REORDER: {
      const orderMaps = Object.fromEntries(action.updatedOrder.map(({ uuid, order }) => [uuid, order]));

      return {
        ...state,
        maps: state.maps
          .map(map => ({ ...map, order: orderMaps[map.uuid] ?? map.order }))
          .sort((a, b) => a.order - b.order),
      };
    }

    case TypeKeys.OFFLINE_MAPS_DOWNLOAD_COMPLETE: {
      const idx = state.maps.findIndex(a => a.uuid === action.uuid);
      return {
        ...state,
        maps: [
          ...state.maps.slice(0, idx),
          {
            ...state.maps[idx],
            size: action.size,
            status: OfflineMapStatus.DOWNLOADED
          },
          ...state.maps.slice(idx + 1)
        ]
      };
    }

    case TypeKeys.OFFLINE_MAPS_DOWNLOAD_ERROR: {
      const idx = state.maps.findIndex(a => a.uuid === action.uuid);
      return {
        ...state,
        maps: [
          ...state.maps.slice(0, idx),
          {
            ...state.maps[idx],
            status: OfflineMapStatus.ERROR
          },
          ...state.maps.slice(idx + 1)
        ]
      };
    }

    case TypeKeys.OFFLINE_MAPS_DOWNLOAD_RETRY: {
      const idx = state.maps.findIndex(a => a.uuid === action.uuid);
      return {
        ...state,
        maps: [
          ...state.maps.slice(0, idx),
          {
            ...state.maps[idx],
            status: OfflineMapStatus.DOWNLOADING
          },
          ...state.maps.slice(idx + 1)
        ]
      };
    }

    case TypeKeys.OFFLINE_MAPS_TOGGLE_AMBIENT: {
      return {
        ...state,
        ambientCacheEnabled: !state.ambientCacheEnabled
      };
    }


    default: {
      return state;
    }
  }
}

export default offlineMapsReducer;
