import { TypeKeys } from '../actions';
import { v4 as uuidv4 } from 'uuid';
import { OfflineMapsService } from '../../services/offline-maps.service';
import { OfflineMap, OfflineMapCreateRequest, OfflineMapStatus, OfflineMapUpdatedOrder } from '../../types/offlineMaps.types';
import { getOfflineMapsMap } from '../selectors';
import { addDays } from 'date-fns';

/******************************************************************************
 * ACTIONS
 *****************************************************************************/
export interface OfflineMapsCreateAction {
  type: TypeKeys.OFFLINE_MAPS_CREATE;
  map: OfflineMap;
}

export interface OfflineMapsDeleteAction {
  type: TypeKeys.OFFLINE_MAPS_DELETE;
  uuids: string[];
}

export interface OfflineMapsReorderAction {
  type: TypeKeys.OFFLINE_MAPS_REORDER;
  updatedOrder: OfflineMapUpdatedOrder[];
}

export interface OfflineMapsDownloadCompleteAction {
  type: TypeKeys.OFFLINE_MAPS_DOWNLOAD_COMPLETE;
  uuid: string;
  size: number;
}

export interface OfflineMapsDownloadErrorAction {
  type: TypeKeys.OFFLINE_MAPS_DOWNLOAD_ERROR;
  uuid: string;
}

export interface OfflineMapsDownloadRetryAction {
  type: TypeKeys.OFFLINE_MAPS_DOWNLOAD_RETRY;
  uuid: string;
}


export interface OfflineMapsToggleAmbientAction {
  type: TypeKeys.OFFLINE_MAPS_TOGGLE_AMBIENT;
}

export type OfflineMapsActions =
  | OfflineMapsCreateAction
  | OfflineMapsDeleteAction
  | OfflineMapsReorderAction
  | OfflineMapsDownloadCompleteAction
  | OfflineMapsDownloadErrorAction
  | OfflineMapsDownloadRetryAction
  | OfflineMapsToggleAmbientAction;

/******************************************************************************
 * ACTION CREATORS
 *****************************************************************************/
export const offlineMapsCreateAction = (map: OfflineMap): OfflineMapsCreateAction => ({
  type: TypeKeys.OFFLINE_MAPS_CREATE,
  map
});

export const offlineMapsDeleteAction = (uuids: string[]): OfflineMapsDeleteAction => ({
  type: TypeKeys.OFFLINE_MAPS_DELETE,
  uuids
});

export const offlineMapsReorderAction = (updatedOrder: OfflineMapUpdatedOrder[]): OfflineMapsReorderAction => ({
  type: TypeKeys.OFFLINE_MAPS_REORDER,
  updatedOrder
});

export const offlineMapsDownloadCompleteAction = (uuid: string, size: number): OfflineMapsDownloadCompleteAction => ({
  type: TypeKeys.OFFLINE_MAPS_DOWNLOAD_COMPLETE,
  uuid,
  size
});

export const offlineMapsDownloadErrorAction = (uuid: string): OfflineMapsDownloadErrorAction => ({
  type: TypeKeys.OFFLINE_MAPS_DOWNLOAD_ERROR,
  uuid
});

export const offlineMapsDownloadRetryAction = (uuid: string): OfflineMapsDownloadRetryAction => ({
  type: TypeKeys.OFFLINE_MAPS_DOWNLOAD_RETRY,
  uuid
});

export const offlineMapsToggleAmbient = (): OfflineMapsToggleAmbientAction => ({
  type: TypeKeys.OFFLINE_MAPS_TOGGLE_AMBIENT
});

/******************************************************************************
 * THUNKS
 *****************************************************************************/

export const offlineMapsCreate = (map: OfflineMapCreateRequest) => async dispatch => {
  const offlineMap: OfflineMap = {
    ...map,
    uuid: uuidv4(),
    size: null,
    order: 0,
    expires: addDays(new Date(), 30).valueOf(),
    status: OfflineMapStatus.DOWNLOADING
  };

  dispatch(offlineMapsCreateAction(offlineMap));

  try {
    const size = await OfflineMapsService.instance.downloadMap(offlineMap);
    dispatch(offlineMapsDownloadCompleteAction(offlineMap.uuid, size));
  } catch (e) {
    dispatch(offlineMapsDownloadErrorAction(offlineMap.uuid));
  }

}

export const offlineMapsDelete = (uuids: string[]) => async (dispatch, getState) => {
  try {
    const offlineMaps = uuids.map(uuid => getOfflineMapsMap(uuid)(getState()));

    dispatch(offlineMapsDeleteAction(uuids));

    for (const offlineMap of offlineMaps) {
      await OfflineMapsService.instance.deleteMap(offlineMap);
    }
  } catch (e) {
    console.warn(e);
  }
};

export const offlineMapsReorder = (updatedOrder: OfflineMapUpdatedOrder[]) => async (dispatch) => {
  try {
    dispatch(offlineMapsReorderAction(updatedOrder));
  } catch (e) {
    console.warn(e);
  }
};

export const offlineMapsRetry = (uuid: string) => async (dispatch, getState) => {
  const offlineMap = getOfflineMapsMap(uuid)(getState());
  dispatch(offlineMapsDownloadRetryAction(offlineMap.uuid));
  console.log('HER?!');

  try {
    const size = await OfflineMapsService.instance.downloadMap(offlineMap);
    dispatch(offlineMapsDownloadCompleteAction(offlineMap.uuid, size));
  } catch (e) {
    dispatch(offlineMapsDownloadErrorAction(offlineMap.uuid));
  }
}
